<template>
  <div class="member-status-container">
    <!-- 返回区域 -->
    <div
      class="back-box"
      :class="{ 'bahasa-style': locale === 'bahasa-Indonesia' }"
      @click="backClick"
    >
      <i class="el-icon-arrow-left"></i>
      <span>{{ $t('GoBack') }}</span>
    </div>

    <!-- 标题区域 -->
    <h3>{{ titleObj.title }}</h3>

    <!-- 内容区域 -->
    <p class="content-box">{{ titleObj.content }}</p>

    <!-- 会员状态区域 -->
    <div class="member-status-box">
      <!-- 搜索区域 -->
      <div class="filter-box">
        <ul class="filter-left">
          <li>
            <span>{{ $t('CompanyName') }}</span>
            <el-input
              v-model="queryInfo.companyName"
              :placeholder="$t('CompanyName')"
            ></el-input>
          </li>
          <li>
            <span>{{ $t('RealName') }}</span>
            <el-input
              v-model="queryInfo.name"
              :placeholder="$t('RealName')"
            ></el-input>
          </li>
          <li>
            <span>{{ $t('GroupType') }}</span>
            <el-select
              v-model="queryInfo.selectGroup"
              :placeholder="$t('PleaseSelect')"
              clearable
            >
              <el-option
                v-for="item in groupList"
                :key="item.groupId"
                :label="item.groupName"
                :value="item.groupId"
              >
              </el-option>
            </el-select>
          </li>
        </ul>
        <div class="filter-right">
          <el-button @click="searchClick">{{ $t('Search') }}</el-button>
        </div>
      </div>

      <!-- 切换区域 -->
      <ul class="toggle-box">
        <li
          v-for="(item, index) in toggleList"
          :key="item.id"
          class="toggle-item"
          :class="{ 'select-li': currentIndex === index }"
          @click="toggleClick(index)"
        >{{ item.title }}</li>
      </ul>

      <!-- 列表区域 -->
      <ul
        v-if="memberStatus.length"
        class="list-box"
      >
        <li
          v-for="item in memberStatus"
          :key="item.id"
          class="list-item"
        >
          <p>{{ item.realName }}（{{ item.groupName }}）</p>
          <p>{{ item.companyName ? item.companyName : '-' }}</p>
          <p>{{ item.mobile }}</p>
          <p>{{ item.email ? item.email : '-' }}</p>
        </li>
      </ul>
      <!-- 无数据 -->
      <div
        v-else
        class="no-data"
      >
        <img src="@/assets/img/no_data.png" />
        <p>{{ $t('NoData') }}</p>
      </div>

      <!-- 分页区域 -->
      <div
        v-if="memberStatus.length"
        class="pagination-box"
      >
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="queryInfo.page"
          layout="prev, pager, next"
          :page-size="queryInfo.pageSize"
          :total="queryInfo.total"
          style="margin: 10px auto 10px 20px"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import mixin from "@/assets/js/mixin";
import api from "@/fetch/api";

export default {
  name: "MemberStatus",
  mixins: [mixin],
  data() {
    return {
      titleObj: {}, // 传过来的数据
      // 查询参数
      queryInfo: {
        page: 1,
        pageSize: 10,
        total: 0,
        companyName: "",
        name: "",
        selectGroup: "", // 选中分组id
      },
      memberStatus: [], // 会员状态列表
      groupList: [], // 分组列表
      toggleList: [
        {
          id: 1,
          title: this.$t("Read"),
        },
        {
          id: 2,
          title: this.$t("Unread"),
        },
      ],
      currentIndex: Number(sessionStorage.getItem("memberCurrentIndex")) || 0, // 当前选中索引
    };
  },
  created() {
    this.titleObj = JSON.parse(this.$route.query.titleObj);
    this.getMemberStatusList();
    this.getGroupMemberList();
  },
  beforeDestroy() {
    sessionStorage.removeItem("memberCurrentIndex");
  },
  methods: {
    // 获取会员状态
    getMemberStatusList() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      const params = {
        chamberId: data.chamberId,
        pageNum: this.queryInfo.page,
        pageSize: this.queryInfo.pageSize,
        isRead: this.currentIndex === 0 ? 1 : 0, // 1:已读、0:未读
        sourceMessageId: this.titleObj.id,
        language: languageObj[locale],
      };

      if (this.queryInfo.companyName.trim())
        params.companyName = this.queryInfo.companyName;
      if (this.queryInfo.name.trim()) params.realName = this.queryInfo.name;
      if (this.queryInfo.selectGroup)
        params.groupId = this.queryInfo.selectGroup;

      api.queryMessageIsReadList(params).then((res) => {
        this.memberStatus = res.data.data.records || [];
        this.queryInfo.total = res.data.data.total;
      });
    },

    // 获取分组成员列表
    getGroupMemberList() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      const params = {
        pageNum: 1,
        pageSize: 999,
        language: languageObj[locale],
        chamberId: data.chamberId,
      };
      api.getGroupByChamberId(params).then((res) => {
        this.groupList = res.data.data.records;
      });
    },

    // 切换
    toggleClick(index) {
      this.currentIndex = index;
      sessionStorage.setItem("memberCurrentIndex", index);
      this.getMemberStatusList();
    },

    // 搜索
    searchClick() {
      this.queryInfo.page = 1;
      this.getMemberStatusList();
    },

    // 返回
    backClick() {
      if (this.titleObj.messageType === 1) {
        sessionStorage.setItem("messageCurrentIndex", 0);
      } else {
        sessionStorage.setItem("messageCurrentIndex", 1);
      }
      this.$router.push({ name: "GroupMessage" });
    },

    // 分页
    handleCurrentChange(page) {
      this.queryInfo.page = page;
      this.getMemberStatusList();
    },
  },
};
</script>

<style lang="scss" scoped>
.member-status-container {
  width: 1600px;
  ul,
  li {
    list-style: none;
  }

  // 返回区域
  .back-box {
    width: 70px;
    text-align: center;
    background-color: #589ef8;
    padding: 5px 0;
    color: #ffffff;
    cursor: pointer;
    border-radius: 5px;
  }
  .bahasa-style {
    width: 95px;
  }

  // 标题区域
  & > h3 {
    margin: 20px 0 5px 0;
    text-align: center;
  }

  // 内容区域
  & > .content-box {
    max-height: 100px;
    overflow-y: auto;
    // 滚动条宽度为0（兼容火狐）
    scrollbar-width: none;
    // 滚动条宽度为0
    &::-webkit-scrollbar {
      display: none;
    }
    padding: 10px 15px;
    margin-bottom: 20px;
    font-size: 14px;
    border: 1px solid #6e6e6e;
    border-radius: 5px;
  }

  // 会员状态区域
  .member-status-box {
    border: 1px solid #6e6e6e;
    border-radius: 2px;

    // 搜索区域
    .filter-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;
      & > .filter-left {
        display: flex;
        li {
          margin-right: 30px;
        }
        li:last-child {
          margin-right: 0;
        }
        span {
          margin-right: 5px;
          font-size: 14px;
        }
        .el-input {
          width: 200px;
        }
      }
      & > .filter-right {
        font-size: 14px;
        .el-button {
          background-color: #589ef8;
          color: #ffffff;
        }
      }
    }

    // 切换区域
    .toggle-box {
      display: flex;
      .toggle-item {
        flex: 1;
        text-align: center;
        line-height: 50px;
        font-size: 18px;
        border: 1px solid #6e6e6e;
        border-left: none;
        cursor: pointer;
      }
      .toggle-item:last-child {
        border-right: none;
      }
      // 选中样式
      .select-li {
        background-color: #589ef8;
        color: #ffffff;
      }
    }

    // 列表区域
    .list-box {
      max-height: 510px;
      overflow-y: auto;
      // 滚动条宽度为0（兼容火狐）
      scrollbar-width: none;
      // 滚动条宽度为0
      &::-webkit-scrollbar {
        display: none;
      }
      .list-item {
        display: flex;
        line-height: 50px;
        font-size: 14px;
        border-bottom: 1px solid #6e6e6e;
        & > p {
          flex: 1;
          text-align: center;
        }
      }
      .list-item:nth-child(2n) {
        background-color: #f3f3f3;
      }
    }

    // 无数据
    .no-data {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 540px;
      p {
        margin-top: 15px;
        color: #bbbbbb;
      }
    }
  }
}
</style>